$(document).ready(function () {
    var $li = $('.ul').find('a');
    var $i = $('.ul').find('i');
    var $menuChildren = $('.menu-children').find('div');
    $($li).on('click', function (e) {
        $($li).parent().removeClass('bg-brown').addClass('hover');
        $($li).addClass('i-hover');
        $($li).removeClass('black-dropdown');
        $($li).find($i).addClass('text-white');
        var number = e.target.id.match(/\d/g).join("");
        $($menuChildren).addClass('hidden');
        $(this).parent().addClass('bg-brown').removeClass('hover');
        $(this).removeClass('i-hover').addClass('black-dropdown');
        $(this).find($i).removeClass('text-white');
        $('#child-nav-' + number).toggleClass('hidden');
    });

    // close language box on scroll
    $(document).scroll(function () {
        $($menuChildren).addClass('hidden');
        $($li).parent().removeClass('bg-brown').addClass('hover');
        $($li).removeClass('black-dropdown');
        $($li).find($i).addClass('text-white');
    });
$(document).on('click', function (e) {
    var container = $li;
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
        $($menuChildren).addClass('hidden');
        $($li).parent().removeClass('bg-brown').addClass('hover');
        $($li).removeClass('black-dropdown');
        $($li).find($i).addClass('text-white');

    }
})
});
