// slider
var Swipes = new Swiper('.swiper-container-home', {
    loop: true,
    effect: 'fade',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});
var galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    loop: true,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    loop: true,
    thumbs: {
        swiper: galleryThumbs
    }
});
// mobile menu
$(document).ready(function () {
    // menu toggle
    $('#menu-btn').on('click', function () {
        $('#menu-mobile').fadeIn(300);
    });
    $('#close-btn').on('click', function () {
        $('#menu-mobile').fadeOut(300);
    });
    // mobile search
    $('#search-mobile').on('click', function () {
        if ($('.input-search-mobile').css('display') === 'none') {
            $('.input-search-mobile').slideDown();
        } else {
            $('.input-search-mobile').slideUp();
        }
    });
    $('#close-search-mobile').on('click', function () {
        $('.input-search-mobile').slideUp();
    });
    // accordeon toggle
    $('.accordeon-item').on('click', function () {
        var number = this.id.match(/\d/g).join("");
        if ($('#accordeon-child-' + number).css('display') === 'none') {
            $('#accordeon-child-1, #accordeon-child-2, #accordeon-child-3, #accordeon-child-4, #accordeon-child-5, #accordeon-child-6').slideUp(400);
            $('#accordeon-minus-1, #accordeon-minus-2, #accordeon-minus-3, #accordeon-minus-4, #accordeon-minus-5, #accordeon-minus-6').hide();
            $('#accordeon-plus-1, #accordeon-plus-2, #accordeon-plus-3, #accordeon-plus-4, #accordeon-plus-5, #accordeon-plus-6').fadeIn();
            $('#accordeon-child-' + number).slideDown(400);
            $('#accordeon-plus-' + number).hide();
            $('#accordeon-minus-' + number).show()
        } else {
            $('#accordeon-child-' + number).slideUp(400);
            $('#accordeon-plus-' + number).fadeIn();
            $('#accordeon-minus-' + number).hide()
        }
    });
    // tabs
    $('.tab-item').on('click', function () {
        var number = this.id.match(/\d/g).join("");
        if ($('#section-tab-' + number).css('display') === 'none') {
            $('#section-tab-1,#section-tab-2,#section-tab-3').hide();
            $('#tab-1,#tab-2,#tab-3').addClass('border-bottom-1 body-background').removeClass('bg-white border-tab');
            $('#i-tab-1,#i-tab-2,#i-tab-3').removeClass('black-color').addClass('brownLight-color');
            $('#color-tab-1,#color-tab-2,#color-tab-3').removeClass('brownLight-color');
            $('#section-tab-' + number).fadeIn();
            $('#tab-' + number).removeClass('border-bottom-1').addClass('bg-white border-tab');
            $('#color-tab-' + number).removeClass('black-color').addClass('brownLight-color');
            $('#i-tab-' + number).removeClass('border-bottom-1').addClass('black-color');
        }
    });
    // menu select language
    $('#select-language').on('click', function () {
        if ($('#language-block').css('display') == 'none') {
            $('#select-language').css('outline', '17px solid #f1f1f1');
            $('#language-block').show();
        } else {
            $('#select-language').css('outline', 'none');
            $('#language-block').hide();
        }
    });
    // close language box on click outside
    $(document).click(function (e) {
        if (e.target.id !== 'select-language' && e.target.id !== 'language-block') {
            $('#select-language').css('outline', 'none');
            $('#language-block').hide();
        }
        if (e.target.class !== 'close-d' && e.target.id === '') {
            $('.d-down-item').slideUp(200);
        }
        if (e.target.id !== 'product-dropdown' && e.target.id === '') {
            $('.drop-down-child').slideUp(200);
        }
    });

    $('.list-accordion').on('click', function (e) {
        var number = e.target.id.match(/\d/g).join("") - 1;
        console.log(number);
        $('.list-accordion').each(function (i, obj) {
            $('#child-' + i).slideUp(300)
        });
        $('#child-' + number).slideToggle(300)
    });

    // close language box on scroll
    $(document).scroll(function () {
        if ($('#language-block').css('display') !== 'none') {
            $('#select-language').css('outline', 'none');
            $('#language-block').hide();
            $('.input-search-mobile').slideUp();
            $('.d-down-item').slideUp(200);
        }
        if ($('.d-down-item .drop-down-child').css('display') !== 'none') {
            $('.d-down-item').slideUp(200);
            $('.drop-down-child').slideUp(200);
        }
    });
});

$(function() {
    $('.vg-nav').vegasMenu();
});
