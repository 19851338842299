$(document).ready(function() {
    var $menu = $('.dropdown');
    $(document).mouseup(function(e) {
        if (!$menu.is(e.target) // if the target of the click isn't the container...
            &&
            $menu.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $menu.removeClass('is-active');
        }
    });
    $('.toggle').on('click', function() {
        $menu.toggleClass('is-active');

    });
    $(document).scroll(function() {
        if ($menu.hasClass('is-active')) {
            $menu.removeClass('is-active');
        }
    });
});