$(document).ready(function () {
    $(".accordeon .accordeon-item").hide().prev().click(function() {
        $(this).parents(".accordeon").find('.accordeon-item').not(this).slideUp().prev().removeClass("active");
        $(this).next().not(":visible").slideDown().prev().addClass("active");
    });
});


// <div class="accordeon">
//     <div>
//     <h2>Заголовок</h2>
//     <p class="accordeon-item">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam ea, accusamus. Numquam non commodi ipsum debitis repudiandae amet vero itaque, deserunt, ad neque libero quae officia. Facilis non, possimus doloribus! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quis voluptatem repellat? Blanditiis iste, exercitationem porro, magni amet cupiditate enim, labore tenetur, praesentium quam consequuntur nesciunt. Culpa dicta adipisci voluptatem.</p>
// </div>
// <div>
// <h2>Заголовок</h2>
// <p class="accordeon-item">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quis voluptatem repellat? Blanditiis iste, exercitationem porro, magni amet cupiditate enim, labore tenetur, praesentium quam consequuntur nesciunt. Culpa dicta adipisci voluptatem.</p>
// </div>
// <div>
// <h2>Заголовок</h2>
// <p class="accordeon-item">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quis voluptatem repellat? Blanditiis iste, exercitationem porro, magni amet cupiditate enim, labore tenetur, praesentium quam consequuntur nesciunt. Culpa dicta adipisci voluptatem.</p>
// </div>
// <div>
// <h2>Заголовок</h2>
// <p class="accordeon-item">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quis voluptatem repellat? Blanditiis iste, exercitationem porro, magni amet cupiditate enim, labore tenetur, praesentium quam consequuntur nesciunt. Culpa dicta adipisci voluptatem.</p>
// </div>
// <div>
// <h2>Заголовок</h2>
// <p class="accordeon-item">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quis voluptatem repellat? Blanditiis iste, exercitationem porro, magni amet cupiditate enim, labore tenetur, praesentium quam consequuntur nesciunt. Culpa dicta adipisci voluptatem.</p>
// </div>
// </div>
