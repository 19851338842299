$(document).ready(function () {
    $('#tab-parent-1').on('click', function () {
        $('#tab-child-2').removeClass('hidden');
        $('#tab-child-1').addClass('hidden');
        $('#tab-parent-1').addClass('border-bottom-0 bg-white brownLight-color').removeClass('black-color');
        $('#tab-parent-2').removeClass('border-bottom-0 bg-white').addClass('body-background black-color');
    });
    $('#tab-parent-2').on('click', function () {
        $('#tab-child-1').removeClass('hidden');
        $('#tab-child-2').addClass('hidden');
        $('#tab-parent-1').removeClass('border-bottom-0 bg-white').addClass('black-color');
        $('#tab-parent-2').addClass('border-bottom-0 bg-white').removeClass('black-color');
    });
});
